import * as React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';

function NotFoundPage() {
  return (
    <Layout>
      <Seo title="404: Not found" />
      <div>
        <div className="min-h-full bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="mx-auto max-w-max">
            <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
              <div className="py-16">
                <div className="text-center">
                  <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                    404: Not Found
                  </h1>
                  <p className="mt-2 text-base text-gray-500">
                    Page does not exist
                  </p>
                  <div className="mt-6">
                    <a
                      href="/"
                      className="text-base font-medium text-gray-600 hover:text-gray-500"
                    >
                      Go back home
                      <span aria-hidden="true"> &rarr;</span>
                    </a>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
      <h1>404: Not Found</h1>
    </Layout>
  );
}

export default NotFoundPage;
